@font-face {
  font-family: Delvona Light;
  src: url(/fonts/Delvona/delvona-webfont.woff2) format('woff2'),
    url(/fonts/Delvona/delvona-webfont.woff) format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

body {
  overscroll-behavior: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-block-start: unset;
  /* margin-block-end: unset;
  margin-inline-start: unset;
  margin-inline-end: unset; */
  /* font-weight: bold;  */
}

p:last-child {
  margin-block-end: unset;
}

figure,
ul {
  margin-block-start: unset;
  margin-block-end: unset;
  margin-inline-start: unset;
  margin-inline-end: unset;
  padding-inline-start: unset;
}

.grecaptcha-badge {
  visibility: hidden;
}
